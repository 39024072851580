import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BlogPage from "../components/blog/index";
const blog = () => {
  return (
    <Layout>
      <SEO
        title="Blog | Kentucky Tamil Sangam"
        desc="Blog | Kentucky Tamil Sangam"
        pathname="/blog"
        keywords="KYTS - Blog"
      />
      <BlogPage />
    </Layout>
  );
};

export default blog;
