import React from "react"
import { Link } from "gatsby"
const BlogPage = () => {
  return (
    <>
      <div className="page-title-area">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>வலைப்பூ</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>வலைப்பூ</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="team-section pt-70 pb-70">
        <div className="container">
          <div className="team-title-area">
            <div className="row align-items-center">
              <div className="col-lg-7">
                <div className="section-title text-left">
                  <span>
                    <i className="flaticon-support" />
                                        Our Blog
                                    </span>
                  <h2>வலைப்பூ</h2>

                </div>
              </div>
              <div className="col-lg-5">

              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-12">
              <p>
                வணக்கம்.
                        </p>

              <p>குமரிக்கண்டத்திலே தோன்றிய தமிழ் மொழியையும், பண்பாட்டையும் கென்டக்கி மாநிலம் வரை பரப்பும் முயற்சி மற்றும்  இங்கு வாழும் தமிழ் உறவுகளுக்கு ஒரு பாலமாகவும் விளங்கும் கென்டக்கி தமிழ் சங்கத்தோடு பயணிக்க உங்களையும் வரவேற்கிறோம்.
                        </p>

              <p>அன்புடன், <br /><br />

                        வெ. அழகர்சாமி<br />

                        தலைவர் - 2021, கென்டக்கி தமிழ் சங்கம்
                        </p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default BlogPage
